@import '~/styles/constants';

.header {
    z-index: 1051;
}

.login-page {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    .login-validation-error {
        position: absolute;
        text-align: center;
        width: 100%;
        height: 40px;
        background-color: #d65746;
        padding: 10px;
        font-family: 'Ubuntu-Medium';
        color: #fff;
    }

    .generic-validation-error {
        position: fixed;
        z-index: 1051;
        @extend .login-validation-error;
    }

    .login-form-panel-container {
        display: flex;
        flex: 8 1 0;
        align-items: center;
        justify-content: center;
        .login-form-panel {
            width: 420px;
            min-height: 354px;
            border-radius: 3px;
            background: white;
            box-shadow: 0px 0px 6px #00000029;

            .login-form-panel__header {
                padding-top: 20px;
                text-align: center;
                .login-form-panel__logo {
                    margin-top: 10px;
                    margin-bottom: 5px;
                }
            }
            #login-password {
                margin-bottom: 5px;
            }
            #forgot-password-container {
                display: block;
                margin-bottom: 20px;
                a {
                    // without this,
                    // the whitespace next to the link is clickable
                    display: inline;
                    padding-right: 5px;
                }
            }
        }
    }
    .demo-request-container {
        font-family: $hv-font-medium;
        text-align: center;
        color: white;
        flex: 1 1 0;
        a.link-button {
            margin-left: 20px;
        }
    }
}

.login-modal {
    width: 440px;
    min-height: 440px;
    margin-top: 150px;

    .modal-title {
        font-family: $hv-font-normal;
        font-size: 26px;
    }
    .modal-body {
        padding: 20px 20px;
    }
    form {
        font-family: $hv-font;
        margin-bottom: 0px;
        padding: 0px;
    }
}


.lockout-modal {
    width: 760px;
    min-height: 440px;
    margin-top: 150px;

    .modal-title {
        font-family: $hv-font-normal;
        font-size: 26px;
    }
    .modal-body {
        padding: 20px 20px;
    }
    form {
        font-family: $hv-font;
        margin-bottom: 0px;
        padding: 0px;
    }
}
