@import '~/styles/constants';

.marketing-page-background {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .marketing-panel-body {
        height: 100%;
        display: flex;
        flex: 8 1 0;
        align-items: center;

        .marketing-panel {
            width: 590px;
            min-height: 325px;
            border-radius: 3px;
            background: white;
            box-shadow: 0px 0px 6px #00000029;

            .marketing-header-box {
                border-radius: 3px 3px 0px 0px;

                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-position: center;
                height: 165px;

                .marketing-header-content {
                    color: white;
                    padding: 35px 10px 10px 20px;
                    position: relative;
                    z-index: 1;
                    border-radius: 3px 3px 0px 0px;

                    .marketing-header__tag {
                        position: absolute;
                        top: 0;
                        left: 0;
                        padding: 5px 10px;
                        letter-spacing: 1.47px;
                        border-radius: 3px 0px 0px 0px;
                    }
                    .marketing-header__main-header {
                    }
                    .marketing-header__sub-header {
                        max-width: 425px;
                    }
                }
            }

            .marketing-panel__description {
                padding: 20px;
            }
        }
    }

    .marketing-page-footer {
        display: flex;
        flex: 1 1 0;
    }
}
