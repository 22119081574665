.content-container {
    display: flex;
    height: 100%;

    .login-form-container,
    .marketing-container {
        flex: 1 1 0;
        height: 100%;
        vertical-align: top;
    }

    .login-form-container {
        background: transparent linear-gradient(44deg, #144a65 0%, #3f86a8 100%)
            0% 0% no-repeat;
    }
}
