@import '~/styles/constants';

.login-modal {
    .modal-content {
        .modal-title {
            .glyphicons-info-sign {
                font-size: 15px;
                color: #72b456;
                margin-left: 5px;
                vertical-align: baseline;
            }
        }
    }

    .token-input {
        background: #f4f4f4 0% 0% no-repeat padding-box;
        border: 1px solid #d6d6d6;
        border-radius: 3px;
        opacity: 1;
    }
}

.two-factor-tooltip .tooltip {
    max-width: 285px;
    height: 108px;
    opacity: 1;
    left: 1px !important;

    .tooltip-inner {
        background-color: #72b456;
        text-align: left;
        font-family: 'Ubuntu-Medium';
        font-size: 13px;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
    }

    .arrow::before {
        border-bottom-color: #72b456 !important;
    }
}
