@import '~bootstrap/scss/bootstrap';
@import 'constants';

@font-face {
    font-family: 'Ubuntu';
    src: url('./fonts/Ubuntu/Ubuntu-Regular.ttf');
}
@font-face {
    font-family: 'Ubuntu-Light';
    src: url('./fonts/Ubuntu/Ubuntu-Light.ttf');
}
@font-face {
    font-family: 'Ubuntu-Medium';
    src: url('./fonts/Ubuntu/Ubuntu-Medium.ttf');
}

html {
    height: 100%;
}
body {
    // Generically give all text our HV font
    font-family: $hv-font;
    font-size: 13px;
    height: 100vh;
}

pre {
    font-family: $hv-font;
    font-size: 13px;
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    word-break: normal; /* override word-break: break-all; */
}

// Optional classes for different kinds of text
.text--normal {
    font-family: $hv-font-normal;
}
.text--medium {
    font-family: $hv-font-medium;
}
.text--bold {
    font-weight: bold;
}
.text--italics {
    font-style: italic;
}
.text--underlined {
    text-decoration: underline;
}

// Links are light blue, italicized, and underlined
a {
    color: $hv-link-blue;
    font-style: italic;
    text-decoration: underline;
}
a:hover {
    color: $hv-link-hover-blue;
}

// Some direct links look like buttons
a.link-button {
    color: black;
    font-family: $hv-font-medium;
    text-decoration: none;
    font-style: normal;
    padding: 5px 20px;
    background: transparent linear-gradient(180deg, #ffffff 0%, #f1f1f1 100%) 0%
        0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #0000001a;
    border: 1px solid #d6d6d6;
    border-radius: 3px;
}

// Most of our "panels" or "containers" will have this same box-shadow
.shadow-box {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}

.hv-form {
    padding: 10px 20px 20px 20px;
    font-size: 13px;
    font-family: $hv-font-medium;

    p.input-header {
        margin-bottom: 5px;
    }

    a {
        display: block;
        margin-bottom: 20px;
        font-family: $hv-font;
    }

    input:not([type='submit']) {
        display: block;
        width: 100%;
        height: 35px;
        padding-left: 10px;
        margin-bottom: 20px;
        border: solid 1px #e0e0e0;
        border-radius: 3px;
        background-color: $hv-light-bg;
        font-family: $hv-font-medium;
        font-style: italic;
        font-size: 13px;
    }
    input[type='submit'] {
        display: block;
        padding: 1px 7px 1px;
        float: right;
        width: 100px;
        height: 25px;
        border: none;
        border-radius: 3px;
        color: #fff;
        background: transparent
            linear-gradient(180deg, #72b456 0%, #578c41 100%) 0% 0% no-repeat;
        box-shadow: 0px 0px 3px #00000033;
        font-style: normal;
    }

    input[type='submit']:hover {
        background: none;
        background-color: #578c41;
    }

    input::placeholder {
        color: $hv-placeholder-gray !important;
        font-family: $hv-font-medium;
    }

    .custom-control-label::before,
    .custom-control-label::after {
        top: 1px;
    }
}

:focus,
::before {
    outline: 0 !important;
    box-shadow: none !important;
}

#TFAtooltip {
    cursor: pointer;
}

// Container for buttons that aligns them right (for modals)
.button-container--align-right {
    margin-top: 15px;
    text-align: right;
    button {
        margin-right: 5px;
    }
    button:last-of-type {
        margin-right: 0;
    }
}

button {
    padding: 3px 15px;
    background-image: linear-gradient(#ffffff, #f1f1f1);
    box-shadow: 0px 0px 6px #0000001a;
    border: 1px solid #d6d6d6;
    font-family: $hv-font-medium;
    min-width: 100px;
    height: 25px;
    border-radius: 3px;
}
button:hover {
    background: none;
    background-color: #f1f1f1;
}

button.danger {
    padding: 4px 16px;
    border: none;
    background-image: linear-gradient(#ca6745, #9f5137);
    color: #fff;
}
button.danger:hover {
    background: none;
    background-color: #9f5137;
}

button.success {
    padding: 4px 16px;
    border: none;
    background-image: linear-gradient(#72b456, #578c41);
    color: #fff;
}
button.success:hover {
    background: none;
    background-color: #578c41;
}

.field-level-error {
    position: relative;
    width: fit-content;
    height: fit-content;
    margin-top: -15px;
    margin-bottom: 10px;

    .field-level-error__content {
        padding: 5px 15px 3px 15px;
        background-color: #d65746;
        font-family: 'Ubuntu-Medium';
        color: #fff;
        border-radius: 3px;
    }
    .arrow {
        position: absolute;
        width: 100%;
        top: 0;
        text-align: center;
    }
    .arrow::before,
    .arrow::after {
        position: absolute;
        margin-top: -5px;
        content: '';
        border-color: transparent;
        border-style: solid;
    }
    .arrow::before {
        border-width: 0 0.4rem 0.4rem 0.4rem;
        border-bottom-color: #d65746;
    }
    .arrow::after {
        border-width: 0 0.4rem 0.4rem 0.4rem;
        border-bottom-color: #d65746;
    }
}

.alert-success {
    line-height: 24px;
    color: #1b1b1b;
    background-color: #e8f5de;
    border-color: #e8f5de;
    margin-bottom: 30px;
}
.alert--no-margin {
    margin: 0;
}
