$hv-font: 'Ubuntu-Light', sans-serif;
$hv-font-normal: 'Ubuntu', sans-serif;
$hv-font-medium: 'Ubuntu-Medium', sans-serif;
$hv-font-light: 'Ubuntu-Light', sans-serif;

$hv-font-color: #1b1b1b;
$hv-color: #e8f5de;
$hv-light-bg: #f1f1f1;
$hv-table-bg: #f4f4f4;

$hv-blue: #3398da;
$hv-link-blue: #00b6c3;
$hv-link-hover-blue: #098088;
$hv-selected-gray: #dedede;
$hv-placeholder-gray: #c4c4c4;
