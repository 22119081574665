@import '~/styles/constants';

// MAIN HEADER
.navigation-bar {
    z-index: 1;
    height: 40px;
    width: 100%;
    background-color: #fff;
    padding: 0px;
    border-bottom: 1px solid #d6d6d6;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    .menu-button {
        height: 100%;
        display: block;
        border-top: none;
        border-bottom: none;
        border-radius: 0;
    }
    ul.navbar-nav {
        margin-right: 30px;
    }
}

.menu-modal {
    font-family: $hv-font-normal;
    height: 100%;
    width: 220px;
    margin: 0;
    .modal-content {
        height: 100%;
        border: none;
        border-radius: 0;
        .modal-body {
            padding: 0;
        }
    }
    .dropdown-divider {
        margin-top: 0;
        margin-bottom: 0;
    }
    .dropdown-item {
        padding: 1rem;
        cursor: pointer;
        text-decoration: none;
        font-style: normal;
    }
}

.menu-button-container {
    flex: 1 0 0;
}

.header-logo {
    // Center the logo. The padding-right accounts for the menu "burger".
    height: 24px;
    margin: auto;
    display: block;
    flex: 8 0 0;
}

.corner-link-container {
    flex: 1 0 0;
    .corner-link {
        float: right;
        font-family: $hv-font-normal;
        padding: 1rem;
        cursor: pointer;
        text-decoration: none;
        font-style: normal;
        color: black;
        padding-right: 20px;
    }
}
