.email-sent.login-modal {
    .alert-success {
        // This particular alert-success isn't positioned above
        // a form, so it doesn't need spacing.
        margin-bottom: 0px;
    }
}

#not-working-text {
    margin-bottom: 10px;
}

#email-sent-divider {
    margin-top: 30px;
}
