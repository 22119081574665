@import '~/styles/constants';

.tos.login-modal {
    width: 720px;
    max-width: none;
    height: 550px;
    margin-top: 100px;

    .modal-content {
        height: 100%;

        .modal-title {
            .last-updated {
                font-family: $hv-font-medium;
                font-size: 13px;
            }
        }
        .modal-body {
            overflow-y: scroll;
        }
    }
}
