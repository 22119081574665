@import '~/styles/constants';

@import 'node_modules/react-widgets/lib/scss/react-widgets.scss';
@import '~react-widgets/lib/scss/react-widgets';

.activate-page {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;

  .password-validation-error {
    position: absolute;
    text-align: center;
    width: 100%;
    height: 40px;
    background-color: #d65746;
    padding: 10px;
    font-family: $hv-font-medium;
    color: #fff;
  }
}

.activate-container {
  display: flex;
  align-items: center;
  height: 100%;
}

.activate-box {
  width: 720px;
  height: 545px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 2px solid #E3E3E3;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
}

.activate-box-header {
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 20px;
  font-family: $hv-font-normal;
  font-size: 26px;
  color: $hv-font-color;
  border-bottom: 1px solid #D6D6D6;
  width: 100%;
}

.activate-box-welcome-msg {
  margin: 20px 20px 0px;
  padding-left: 20px;
  align-items: center;
  display: flex;
  height: 54px;
  font-family: $hv-font-light;
  font-size: 13px;
  color: $hv-font-color;
  background: #E8F5DE 0% 0% no-repeat padding-box;
  border-radius: 3px;
}

.activate-box-contact-details-section {
  padding: 0px 20px 10px;
  display: grid;
  border-bottom: 1px solid #D6D6D6;
}

.activate-box-section-title {
  font-family: $hv-font-normal;
  font-size: 14px;
  color: $hv-font-color;
  margin-bottom: 5px;
  margin-top: 15px;
}

.activate-box-input {
  width: 335px;
  height: 35px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #D6D6D6;
  border-radius: 3px;
  padding: 10px;
  font-family: $hv-font-light;
  font-size: 14px;
  color: $hv-font-color;
  letter-spacing: -0.26px;
  margin-bottom: 10px;
}

.activate-box-name-row {
  display: flex;
  justify-content: space-between;
}

.activate-box-role-section {
  padding-left: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #D6D6D6;
}

.activate-box-password-section {
  padding-left: 20px;
  padding-bottom: 10px;
  display: grid;
}

.activate-box-password-requirement {
  font-family: $hv-font-light;
  font-size: 13px;
  color: $hv-font-color;
  font-style: italic;
}

.activate-box-dropdown-parent {
  width: 335px;
  height: 35px;
}

.activate-box-dropdown {
  font-family: "Ubuntu-Light";
  font-size: 13px;

  button {
    text-align: end;
    padding-right: 10px;
  }

  button:hover {
    background-color: transparent;
  }

  &:hover {
     border-color: darkgrey;
  }

  & > .rw-dropdown-list-input {
    padding: 0 0 0 9px;
    cursor: pointer;
    color: #1b1b1b;
  }

  &.rw-state-focus,
  .rw-state-focus > & {
    &,
    &:hover {
      background-color: #fff;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
      border: 1px solid #a0a0a0;
    }
  }
}

.activate-box-button-section {
  display: block;
  text-align: end;
  padding-right: 20px;
}


