$header-height: 40px;

#app {
    height: 100%;
    .modal-overlay {
        position: fixed;
        height: 100%;
        width: 100%;
        background: #000000 0% 0% no-repeat;
        opacity: 0.5;
        z-index: 4;
        display: none; // FOR NOW
    }
    .main-display {
        display: flex;
        flex-flow: column;
        height: 100%;
        .header {
            height: $header-height;
            max-height: $header-height;
        }
        .underneath-header {
            // TODO: Use flex-direction column and flex-grow
            height: calc(100% - #{$header-height});
        }
    }
    .timeout-overlay {
        background: transparent linear-gradient(44deg, #144a65 0%, #3f86a8 100%)
        0% 0% no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }
    .timeout-container {
        width: 500px;
        background-color: #FFF;
        border-radius: 4px;
    }
    .timeout-header {
        height: 10px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        background-color: #3F86A8;
    }
    .timeout-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 30px 40px;
        gap: 30px;
        font-family: 'Ubuntu-Medium';
    }
    .timeout-message {
        font-size: 22px;
    }
    .timeout-button {
        background: transparent linear-gradient(180deg, #72B456 0%, #578C41 100%);
        color: #FFF;
        width: 100%;
        height: 30px;
        border-radius: 4px;
        &:hover {
            background: #578C41;
        }
    }
}
